import {Fragment, PropsWithChildren, useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {Error} from "./Error";
import {ApiGetCompany} from "../../api/Api";
import {CompanyGetResponse} from "../../typings";

export const CompanyCheck = ({children}: PropsWithChildren) => {

    const {company, setSpinner, setCompany} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCompanyErr, setIsCompanyErr] = useState<boolean>(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const companyId = params.get("company") ?? "";
        if (!company && companyId !== "") {
            // URLパラメータから取得
            getCompany(companyId);
            return;
        }
        // どちらもない場合は不正なアクセス
        setIsCompanyErr(true);

    }, []);

    // 企業情報を取得
    const getCompany = (companyId: string) => {

        setSpinner(true);

        setIsLoading(true);

        ApiGetCompany(companyId)
            .then((res) => {

                const data = res.data as CompanyGetResponse;
                setCompany(data.company);
                setIsLoading(false);

            })
            .catch((err) => {

                console.log(err);

            })
            .finally(() => {
                setSpinner(false);
                setIsLoading(false);
            })

    }


    if (isLoading) {
        // 読み込み中は何も表示しない
        return null;
    }

    if (isCompanyErr) {
        // 企業エラー
        return <Error message="企業IDが指定されていません。URLが正しくありません。"/>
    }

    if (!company) {
        // 企業が取得できなければエラー
        return null;
    }

    return <Fragment>
        {children}
    </Fragment>
};