import styled from "styled-components";

interface Props {
    message: string;
}

export const Error = (props: Props) => {

    return <ErrorDiv>
        {props.message}
    </ErrorDiv>

};

const ErrorDiv = styled.div`
  background-color: #cc0000;
  color: #fff;
  padding: 30px;
  position: fixed;
  font-size: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  border: 2px solid #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, .4);
`;