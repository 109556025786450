import {useContext, useEffect, useState} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {zerofill} from "../../utility/utility";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import {ApiCheck} from "../../api/Api";
import {CheckResponse} from "../../typings";
import {Header} from "../common/Header";
import {StyledLayout} from "./StyledLayout";
import icoAttendance from "../../images/attendance.svg";
import icoLeaving from "../../images/leaving.svg";

// ログイン後TOPページ
export const Home = () => {

    const {company, setSpinner} = useContext(AppContext);
    const [dt, setDt] = useState<Date>(new Date());
    const [isAttendance, setIsAttendance] = useState<boolean>(false);
    const [isLeaving, setIsLeaving] = useState<boolean>(false);

    useEffect(() => {

        let tm = 0;

        tm = window.setInterval(() => {
            setDt(new Date());
        }, 1000);

        // 送信状況のチェック
        checkSendingStatus();

        return () => {
            window.clearInterval(tm);
        };

    }, []);

    // 送信状況のチェック
    const checkSendingStatus = () => {

        setSpinner(true);

        ApiCheck()
            .then((res) => {
                const data = res.data as CheckResponse;
                setIsAttendance(data.attendance);
                setIsLeaving(data.leaving);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // 日付を返す
    const getDateStr = (): string => {
        const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
        return `${dt.getMonth() + 1}月${dt.getDate()}日(${weekdays[dt.getDay()]})`;
    };

    // 時間を返す
    const getTimeStr = (): string => {
        return `${zerofill(dt.getHours(), 2)}:${zerofill(dt.getMinutes(), 2)}:${zerofill(dt.getSeconds(), 2)}`;
    };

    let attendanceUrl = `/register/step1?company=${company?.id}&type=attendance`;
    let leavingUrl = `/register/step2?company=${company?.id}&type=leaving`;
    if (company?.facility?.with_mimosys) {
        attendanceUrl = `/register/mimosys?company=${company?.id}&type=attendance&offset=0`;
        leavingUrl = `/register/mimosys?company=${company?.id}&type=leaving&offset=0`;
    }

    return <AuthCheck>

        <Header/>

        <Screen1Style>

            <div className="date">
                <span>{getDateStr()}</span>
                <h3>{getTimeStr()}</h3>
            </div>

            <div className="buttons">
                {!isAttendance && <NavLink className="attendance" to={attendanceUrl}><img src={icoAttendance} alt="" />運転前</NavLink>}
                {isAttendance && <span className="attendance disabled"><img src={icoAttendance} alt="" />運転前</span>}

                {!isLeaving && <NavLink className="leaving" to={leavingUrl}><img src={icoLeaving} alt="" />運転後</NavLink>}
                {isLeaving && <span className="leaving disabled"><img src={icoLeaving} alt="" />運転後</span>}
            </div>

            <div className="history">
                <NavLink to={`/history?company=${company?.id}`}>今月の履歴</NavLink>
            </div>

        </Screen1Style>

    </AuthCheck>

};

const Screen1Style = styled(StyledLayout)`

  .date {
    margin-bottom: 48px;

    span {
      text-align: center;
      display: block;
      font-size: 20px;
    }

    h3 {
      text-align: center;
      display: block;
      font-size: 50px;
      font-weight: 400;
      line-height: 1;
    }
  }

  .buttons {
    margin-bottom: 90px;

    a, span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      text-decoration: none;
      padding: 10px;
      background-color: #031F69;
      color: #fff;
      font-size: 33px;
      border: none;
      border-radius: 2px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
      
      &.leaving {
        background-color: #CE6E32;
      }
      
      img {
        margin-right: 4px;
      }
    }

    // 無効なボタン
    span {
      background-color: #ccc;


      &.leaving {
        background-color: #ccc;
      }

    }
  }

  .history {
    text-align: center;

    a {
      color: #000;
    }
  }

`