import {useEffect, useState} from "react";

interface Props {
    file: File | null;
}

// 選択中の画像のプレビュー
export const Preview = (props: Props) => {

    const [src, setSrc] = useState<string|null>(null);

    useEffect(() => {

        if(!props.file) {
            setSrc(null);
            return;
        }

        const reader = new FileReader();

        reader.onload = () => {
            setSrc(reader.result as string);
        };

        reader.readAsDataURL(props.file);

    }, [props.file]);

    if (!src) {
        return null;
    }

    return <div className="preview">

        {src && <img src={src} alt="選択中の画像"/>}

    </div>
};