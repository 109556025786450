import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {StyledLoginForm} from "./StyledLoginForm";
import {LoginRequest, LoginResponse} from "../../typings";
import {AppContext} from "../../context/AppContext";
import {ApiLogin} from "../../api/Api";
import {FormError} from "../common/FormError";
import {Message} from "../common/Message";
import {Navigate, NavLink} from "react-router-dom";

export const Login = () => {

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {company, setSpinner, setToken, setEmployee, employee} = useContext(AppContext);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [message, setMessage] = useState<string|null>(null);

    useEffect(() => {
        if (employee) {
            setIsSuccess(true);
        }
    }, []);

    // フォーム変更ハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "email":
                setEmail(e.currentTarget.value);
                break;
            case "password":
                setPassword(e.currentTarget.value);
                break;
        }
    };

    // フォーム送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        const req: LoginRequest = {
            company_id: company?.id ?? "",
            email: email,
            password: password,
        }

        setErrors({});
        setMessage(null);

        setSpinner(true);

        ApiLogin(req)
            .then((res) => {

                const data = res.data as LoginResponse;

                setEmployee(data.employee);
                setToken(data.token);
                setIsSuccess(true);

            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    console.log(err.response.data);

                    const keys = Object.keys(err.response.data);

                    const _tmp: { [key: string]: string } = {};

                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });

                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    if (isSuccess) {
        return <Navigate to={`/?company=${company?.id}`}/>
    }

    return <StyledLoginForm>

        <Message message={message}/>

        <h1>TRIBAWL</h1>

        <form onSubmit={onSubmit}>
            <div>
                <input type="email" name="email" placeholder="ID" value={email} onChange={onChange}/>
                <FormError message={errors["email"]}/>
            </div>
            <div>
                <input type="password" name="password" placeholder="パスワード" value={password} onChange={onChange}/>
                <FormError message={errors["password"]}/>
            </div>

            <button>ログイン</button>

            <div className="forgot">
                <NavLink to={`/forgot?company=${company?.id}`}>パスワードを忘れた方はこちら</NavLink>
            </div>

        </form>

    </StyledLoginForm>
};