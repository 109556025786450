import {useContext, useEffect} from "react";
import {AppContext} from "../../context/AppContext";
import {useNavigate} from "react-router-dom";
import {ApiLogout} from "../../api/Api";

export const Logout = () => {

    const {setToken, setEmployee, setSpinner, company} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        sendLogout();
    }, []);

    const sendLogout = () => {

        setSpinner(true);

        ApiLogout()
            .finally(() => {
                setToken(null);
                setEmployee(null);
                setSpinner(false);
                navigate(`/?company=${company?.id}`);
            });
    };

    return null;

};