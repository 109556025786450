import {Fragment, PropsWithChildren, useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {Navigate} from "react-router-dom";
import {ApiMe} from "../../api/Api";
import {MeResponse} from "../../typings";

// AuthCheck はログイン認証が必須なページの認証チェックラッパー
export const AuthCheck = ({children}: PropsWithChildren<any>) => {

    const {employee, company, setEmployee, setSpinner} = useContext(AppContext);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!employee) {
            getMe();
        }
    }, [employee]);

    // 自分自身の情報取得
    const getMe = () => {

        setSpinner(true);

        ApiMe()
            .then((res) => {
                const data = res.data as MeResponse;
                setEmployee(data.employee);
                setIsLoaded(true);
            })
            .catch((err) => {
                setIsLoaded(true);
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    }

    if (!employee && !isLoaded) {
        return null;
    }

    if (!employee && isLoaded) {
        // 従業員が取得できず、読み込みも完了している場合、ログインページにリダイレクト
        return <Navigate to={`/login?company=${company?.id}`}/>
    }

    return <Fragment>
        {children}
    </Fragment>

};