import {AuthCheck} from "../common/AuthCheck";
import styled from "styled-components";
import {Fragment, useContext, useEffect, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {ListByEmployeeRequest, ListByEmployeeResponse} from "../../typings";
import {createJaMdFromYmd, createTime, createYm} from "../../utility/utility";
import {ApiHistory} from "../../api/Api";
import {AlcoholHistoryData} from "../../../../react-common/typings";
import {NavLink} from "react-router-dom";
import {Header, HeaderHeight} from "../common/Header";
import {StyledLayout} from "./StyledLayout";
import down from "../../images/down.svg";
import up from "../../images/up.svg";

// 履歴画面
export const History = () => {

    const {employee, company, setSpinner} = useContext(AppContext);
    const [dt, setDt] = useState<Date>(new Date());
    const [histories, setHistories] = useState<AlcoholHistoryData[]>([]);
    const [openedIds, setOpenedIds] = useState<string[]>([]);

    useEffect(() => {

        if (!employee) {
            return;
        }

        // 履歴取得
        getHistories();

        // クリア
        setOpenedIds([]);

    }, [employee, dt]);

    // 履歴を取得
    const getHistories = () => {

        setSpinner(true);

        const req: ListByEmployeeRequest = {
            page: 1,
            limit: 31, // ページングは利用しない
            ym: createYm(dt),
        };

        ApiHistory(req)
            .then((res) => {
                const data = res.data as ListByEmployeeResponse;
                setHistories(data.alcohol_histories);
            })
            .catch((err) => {
                console.log(err);

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // 前月をクリック
    const onClickPrev = () => {
        const newDt = new Date(dt.getFullYear(), dt.getMonth() - 1, 1);
        setDt(newDt);
    };

    // 翌月をクリック
    const onClickNext = () => {
        const newDt = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
        setDt(newDt);
    };

    // 行の開閉
    const onToggle = (id: string) => {
        let next = [...openedIds];
        const offset = next.indexOf(id);
        if (offset === -1) {
            next.push(id);
        } else {
            next.splice(offset, 1);
        }

        setOpenedIds(next);
    };


    return <AuthCheck>

        <Header/>

        <Screen4Style>

            <h3>
                <span onClick={onClickPrev}>&lt; 前月</span>
                <strong>{dt.getMonth() + 1}月の履歴</strong>
                <span onClick={onClickNext}>次月 &gt;</span>
            </h3>


            <table>
                <thead>
                <tr>
                    <th>日付</th>
                    <th>運転前</th>
                    <th>運転後</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {histories.map((d) => {

                    const isOpened = openedIds.indexOf(d.id) !== -1;

                    return <Fragment key={`history-${d.id}`}>
                        <tr onClick={() => onToggle(d.id)}>
                            <td>{createJaMdFromYmd(d.ymd)}</td>
                            <td className="fixed">
                                {d.attendance_time ? createTime(d.attendance_time) : "-"}
                                {(isOpened && d.attendance_time) && <div>登録済み<br/>{d.attendance_address}</div>}
                                {(isOpened && !d.attendance_time) && <div>未登録</div>}
                            </td>
                            <td className="fixed">{d.leaving_time ? createTime(d.leaving_time) : "-"}
                                {(isOpened && d.leaving_time) && <div>登録済み<br/>{d.leaving_address}</div>}
                                {(isOpened && !d.leaving_time) && <div>未登録</div>}
                            </td>
                            <td className="toggle"><span className={isOpened ? "opened" : ""}/></td>
                        </tr>
                    </Fragment>

                })}

                </tbody>

            </table>


            <div className="back">
                <NavLink to={`/?company=${company?.id}`}>戻る</NavLink>
            </div>

        </Screen4Style>

    </AuthCheck>

};


const Screen4Style = styled(StyledLayout)`

  padding-top: ${HeaderHeight + 50}px;
  max-width: 320px;

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    strong {
      font-size: 20px;
      font-weight: 400;

    }

    span {
      font-weight: normal;
      cursor: pointer;
      font-size: 15px;
      color: #0072B9;
    }
  }

  // 戻るボタン
  .back {
    text-align: center;

    a {
      color: #0072B9;
      font-size: 16px;
    }
  }

  // 一覧テーブル
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    margin-bottom: 45px;

    th, td {
      text-align: center;
      vertical-align: top;
      font-weight: 400;
      font-size: 15px;
    }

    thead {
      th {
        background-color: #fff;
        text-align: center;
        padding: 10px 0;
      }
    }

    tbody {

      tr {

        // 奇数行
        &:nth-child(2n+1) {
          background-color: #EAEAEA;
        }

        // 偶数行
        &:nth-child(2n) {
          background-color: #fff;
        }

        td {
          font-size: 13px;
          text-align: center;
          padding: 10px 0;

          &.fixed {
            width: 100px;
          }
          
          // 詳細
          div {
            color: #0072B9;
            line-height: 1.8;
          }

          // 開閉ボタン
          &.toggle {
            padding-top: 10px;

            span {
              display: block;
              width: 13px;
              height: 11px;
              background-repeat: no-repeat;
              background-position: center center;
              background-image: url(${down});

              &.opened {
                background-image: url(${up});
              }
            }
          }

        }
      }
    }

  }

`