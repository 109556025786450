import * as React from "react";
import styled from "styled-components";

interface Props {
    isRecording: boolean;
    onError: (message: string) => void;
}

interface States {
    audioData: Float32Array[];
    volume: number;
}

const ringWidth = 288;
const innerRingWidth = 278;

// 開発時、複数回のuseEffectなどで誤動作しないよう、クラスを使用している。
export class Recorder extends React.Component<Props, States> {

    audioContext: AudioContext | null = null;
    mediaStreamSource: MediaStreamAudioSourceNode | null = null;
    scriptProcessor: ScriptProcessorNode | null = null;
    stream: MediaStream | null = null;
    isRecorderMounted: boolean = false;
    bufferSize: number = 2048;

    constructor(props: Props) {
        super(props);
        this.state = {
            audioData: [],
            volume: 0,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<States>, snapshot?: any) {
        if (!prevProps.isRecording && this.props.isRecording && !this.isRecorderMounted) {
            // 録音状態になって、まだgrabAudioしていない場合
            this.grabAudio();
            this.isRecorderMounted = true;
        }

        if (!prevProps.isRecording && this.props.isRecording && this.isRecorderMounted) {
            // 再開時
            this.onResume();
        }
    }

    componentWillUnmount() {
        if (this.audioContext) {
            this.audioContext
                .close()
                .then(() => {
                    console.log("audioContext closed");
                    this.audioContext = null;
                });
        }

        if (this.mediaStreamSource) {
            console.log("mediaStreamSource disconnected");
            this.mediaStreamSource.disconnect();
        }

        if (this.stream) {
            this.stream.getAudioTracks().forEach((track) => {
                console.log("getAudioTracks stopped");
                track.stop();
            });
            this.stream = null;
        }

        if (this.scriptProcessor) {
            console.log("scriptProcessor disconnected");
            this.scriptProcessor?.disconnect();
            this.scriptProcessor.onaudioprocess = null;
            this.scriptProcessor = null;
        }
    }

    // オーディオデバイスを取得して、録音開始
    grabAudio() {

        console.log("audioContext", this.audioContext);
        console.log("mediaStreamSource", this.mediaStreamSource);
        console.log("scriptProcessor", this.scriptProcessor);
        console.log("stream", this.stream);

        const constraints: MediaStreamConstraints = {
            audio: true,
            video: false,
        };

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            this.props.onError("mediaDevicesが利用できません。");
            return;
        }

        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {

                console.log("getUserMedia完了");

                this.stream = stream;
                this.audioContext = new AudioContext();
                this.scriptProcessor = this.audioContext.createScriptProcessor(this.bufferSize, 1, 1);
                this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
                this.mediaStreamSource.connect(this.scriptProcessor);

                this.scriptProcessor.onaudioprocess = (e: AudioProcessingEvent) => {

                    if (!this.props.isRecording) {
                        // レコーディング中でなければ処理しない
                        return;
                    }

                    const input = e.inputBuffer.getChannelData(0);
                    const bufferData = new Float32Array(this.bufferSize);
                    let sum = 0;
                    for (let i = 0; i < this.bufferSize; i++) {
                        bufferData[i] = input[i];
                        sum += Math.abs(input[i]);
                    }

                    this.setState({
                        audioData: [...this.state.audioData, bufferData],
                        volume: sum / this.bufferSize,
                    });
                };

                this.scriptProcessor.connect(this.audioContext.destination);

            })
            .catch((err) => {
                this.props.onError("オーディオデバイスが利用できません。");
                console.log(err);
            });
    };

    // 再開処理
    onResume() {
        if (this.audioContext && this.scriptProcessor) {
            this.scriptProcessor.connect(this.audioContext.destination);
        }
    }

    // 停止時処理
    onStop() {
        if (this.scriptProcessor) {
            this.scriptProcessor.disconnect();
        }
    }

    // 終了時処理
    onEnd(callback: (base64: string) => void) {
        // WAVEデータに変換
        this.exportWAV()
            .then((url) => {

                console.log("URL length", url.length);

                callback(url);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // WAVEファイルに変換。基本的に以下URLからのコピペ
    // see: https://blog.narumium.net/2020/08/20/web-audio-api%E3%81%A7%E4%BD%9C%E3%81%A3%E3%81%9F%E9%9F%B3%E3%82%92wav%E3%81%A7%E4%BF%9D%E5%AD%98%E3%81%99%E3%82%8B/
    exportWAV(): Promise<string> {

        return new Promise((resolve, reject) => {

            if (!this.audioContext) {
                reject();
                return;
            }

            // WAV変換
            const encodeWAV = (samples: Float32Array, sampleRate: number) => {
                const buffer = new ArrayBuffer(44 + samples.length * 2);
                const view = new DataView(buffer);

                const writeString = (view: any, offset: any, str: string) => {
                    for (let i = 0; i < str.length; i++) {
                        view.setUint8(offset + i, str.charCodeAt(i));
                    }
                };

                const floatTo16BitPCM = (output: any, offset: any, input: any) => {
                    for (let i = 0; i < input.length; i++, offset += 2) {
                        const s = Math.max(-1, Math.min(1, input[i]));
                        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
                    }
                };

                writeString(view, 0, "RIFF"); // RIFFヘッダ
                view.setUint32(4, 32 + samples.length * 2, true); // これ以降のファイルサイズ
                writeString(view, 8, "WAVE"); // WAVEヘッダ
                writeString(view, 12, "fmt "); // fmtチャンク
                view.setUint32(16, 16, true); // fmtチャンクのバイト数
                view.setUint16(20, 1, true); // フォーマットID
                view.setUint16(22, 1, true); // チャンネル数
                view.setUint32(24, sampleRate, true); // サンプリングレート
                view.setUint32(28, sampleRate * 2, true); // データ速度
                view.setUint16(32, 2, true); // ブロックサイズ
                view.setUint16(34, 16, true); // サンプルあたりのビット数
                writeString(view, 36, "data"); // dataチャンク
                view.setUint32(40, samples.length * 2, true); // 波形データのバイト数
                floatTo16BitPCM(view, 44, samples); // 波形データ

                return view;
            };

            const mergeBuffers = (audioData: Float32Array[]) => {
                const sl = audioData.reduce((a: number, c: any) => a + c.length, 0);
                const samples = new Float32Array(sl);
                let sampleIdx = 0;
                for (let i = 0; i < audioData.length; i++) {
                    for (let j = 0; j < audioData[i].length; j++) {
                        samples[sampleIdx] = audioData[i][j];
                        sampleIdx++;
                    }
                }
                return samples;
            };

            const dataView = encodeWAV(mergeBuffers(this.state.audioData), this.audioContext?.sampleRate);
            const audioBlob = new Blob([dataView], {type: "audio/wav"});

            let reader = new FileReader();
            reader.readAsDataURL(audioBlob);

            reader.onload = () => {
                resolve(reader.result as string);
            };
        });

    }

    render(): JSX.Element | null {

        const borderWidth = this.state.volume * 100;
        const outerWidth = ringWidth + borderWidth * 2;
        const offset = -((outerWidth - innerRingWidth) / 2 + 1); // 1は若干ずれるので補正している

        return <React.Fragment>
            {this.state.volume >= 0.01 && <StyledRing
                className="volume-ring"
                style={{
                    borderWidth: `${borderWidth}px`,
                    left: `${offset}px`,
                    top: `${offset}px`,
                }}
            />}

        </React.Fragment>;
    }

}

const StyledRing = styled.div`
  width: ${ringWidth}px;
  height: ${ringWidth}px;
  border-radius: 50%;
  border: 1px solid rgba(3, 31, 105, 0.6);
  position: absolute;
  box-sizing: content-box;

`
