import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {Header, HeaderHeight} from "../common/Header";
import styled from "styled-components";
import {StyledLayout} from "./StyledLayout";
import {AppContext} from "../../context/AppContext";
import {Message} from "../common/Message";
import btnMic from "../../images/btn-mic.svg";
import btnStop from "../../images/btn-stop.svg";
import btnRedo from "../../images/btn-undo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {Recorder} from "../common/Recorder";
import {MobileCheck} from "../common/MobileCheck";
import {ApiRegisterVoice} from "../../api/Api";
import {RegisterVoiceRequest} from "../../typings";
import {CommonApiResponse} from "../../../../react-common/typings";

export const RegisterMimosys = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {company, setSpinner} = useContext(AppContext);
    const [offset, setOffset] = useState<number>(0);
    const [message, setMessage] = useState<string | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [historyType, setHistoryType] = useState<string>("");
    const [audioData, setAudioData] = useState<string[]>([]);
    const recorderRef = useRef<Recorder>(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const offset = parseInt(params.get("offset") ?? "0");
        const historyType = params.get("type") ?? "";
        setOffset(offset);
        setHistoryType(historyType);

        if (offset >= 1 && audioData.length !== offset) {
            // 2ページ目以降で、オーディオデータがない場合は、戻す
            navigate(`/register/mimosys?company=${company?.id}&type=${historyType}&offset=0`);
        }

    }, [location]);

    if (!company?.mimosys_phases) {
        // 設問が未設定の場合、エラー
        return <AuthCheck>
            <Header/>
            <StyledMimosys phraseNum={0}>
                <Message message="読み上げ文が未設定です。管理者にお問い合わせください。"/>
            </StyledMimosys>
        </AuthCheck>
    }

    // フレーズ数
    const phraseNum = company?.mimosys_phases.length;

    // ドットの作成
    const createDots = (): JSX.Element[] => {

        const dots: JSX.Element[] = [];

        for (let i = 0; i < phraseNum; i++) {

            let cls = [];
            if (i <= offset) {
                cls.push("active")
            }
            dots.push(<li key={`dots-${i}`} className={cls.join(" ")}>{i + 1}</li>)
        }

        return dots;
    };

    // 録音開始
    const onStart = (e: React.MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        setIsRecording(true);
    };

    // 録音中のエラー
    const onError = (message: string): void => {
        setMessage(message);
        setIsRecording(false);
    };

    // 録音停止時
    const onStop = (e: React.MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        if (recorderRef.current) {

            setSpinner(true);

            recorderRef.current.onStop();

            recorderRef.current.onEnd((base64: string) => {

                setSpinner(false);
                setIsRecording(false);

                let next = [...audioData];
                next[offset] = base64;
                setAudioData(next);
            });
        }
    };

    // キャンセル時
    const onCancel = (e: React.MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        if (recorderRef.current) {

            setSpinner(true);

            recorderRef.current.onEnd(() => {

                setSpinner(false);
                setIsRecording(false);

                let next = [...audioData];
                delete next[offset];
                setAudioData(next);
            });
        }
    };

    // 前の文章クリック時
    const onClickPrev = (e: React.MouseEvent<HTMLLIElement>): void => {
        e.preventDefault();
        navigate(`/register/mimosys?company=${company.id}&type=${historyType}&offset=${offset - 1}`);
    };

    // 次の文章クリック時
    const onClickNext = (e: React.MouseEvent<HTMLLIElement>): void => {
        e.preventDefault();
        navigate(`/register/mimosys?company=${company.id}&type=${historyType}&offset=${offset + 1}`);
    };

    // 録音完了、次へクリック時
    const onComplete = (e: React.MouseEvent<HTMLLIElement>): void => {

        e.preventDefault();
        setSpinner(true);

        const req: RegisterVoiceRequest = {
            encoded_data: audioData,
        }

        ApiRegisterVoice(req)
            .then((res) => {
                const data = res.data as CommonApiResponse;

                // キーをローカルストレージに保存
                localStorage.setItem("mimosys_temp_key", data.message);

                // 画面遷移
                if (historyType === "attendance") {
                    // 車両選択へ
                    navigate(`/register/step1?company=${company.id}&type=${historyType}`);
                } else {
                    // 画像登録へ
                    navigate(`/register/step2?company=${company.id}&type=${historyType}`);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    const hasPrev = offset >= 1;
    const hasNext = offset + 1 < phraseNum && audioData[offset] !== undefined;

    const isComplete = audioData.length === phraseNum;

    return <AuthCheck>

        <Header/>

        <MobileCheck>

            <StyledMimosys phraseNum={phraseNum}>

                <Message message={message}/>

                <ul className="pager">
                    {!hasPrev && <li className="disabled">&lt; 前の文章</li>}
                    {hasPrev && <li onClick={onClickPrev}>&lt; 前の文章</li>}
                    {!hasNext && <li className="disabled">次の文章 &gt;</li>}
                    {hasNext && <li onClick={onClickNext}>次の文章 &gt;</li>}
                </ul>

                <div className="phrase">
                    <h4>{company?.mimosys_phases[offset]}</h4>
                    <p>と話しかけてください</p>
                    {/*レコーダー（入力表示のためにここに配置）*/}
                    <Recorder isRecording={isRecording} onError={onError} ref={recorderRef}/>
                </div>

                <ul className="dots">
                    {createDots()}
                </ul>

                {!isRecording && <div className="btn-area">
                    <span onClick={onStart} className="btn">録音開始</span>
                </div>}

                {isRecording && <div className="btn-area">
                    <span className="undo" onClick={onCancel}>やり直し</span>
                    <span className="btn stop" onClick={onStop}>録音停止</span>
                </div>}

                {isComplete && <span className="btn-next" onClick={onComplete}>次へ</span>}

            </StyledMimosys>

        </MobileCheck>
    </AuthCheck>

};


const dotsWh = 20;

interface StyleProps {
    phraseNum: number;
}

const StyledMimosys = styled(StyledLayout).attrs<StyleProps>(() => {
})<StyleProps>`
  padding: ${HeaderHeight + 20}px 20px 30px 20px;
  max-width: none;

  .pager {
    display: flex;
    padding: 0;
    margin: 0 0 30px 0;
    list-style-type: none;
    justify-content: space-between;

    li {
      font-size: 15px;
      color: #0072B9;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.disabled {
        color: #646464;
        cursor: none;

        &:hover {
          text-decoration: none;
        }

      }
    }
  }

  // 読み上げ文章
  .phrase {
    width: 278px;
    height: 278px;
    border-radius: 50%;
    border: 1px solid #031F69;
    margin: 0 auto 17px auto;
    position: relative;

    h4 {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90vw;
      transform: translate(-50%, -60px);
      font-size: 35px;
      color: #031F69;
      line-height: 1.4;
    }

    p {
      width: 90vw;
      text-align: center;
      position: absolute;
      bottom: 65px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
    }
  }
  
  // ドット
  ul.dots {
    list-style-type: none;
    padding: 0;
    margin: 0 auto 40px auto;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      width: ${(props) => props.phraseNum * 30}px;
      z-index: 1;
      height: 1px;
      background-color: #A2A2A2;
    }

    li {
      font-size: 12px;
      text-align: center;
      line-height: ${dotsWh}px;
      width: ${dotsWh}px;
      border-radius: 50%;
      color: #fff;
      font-family: sans-serif;
      margin-right: 20px;
      background-color: #A2A2A2;
      position: relative;
      z-index: 2;

      &.active {
        background-color: #031F69;
      }

      &:last-child {
        margin-right: 0;
      }
    }

  }

  // ボタンエリア
  .btn-area {
    position: relative;

    span.btn {
      display: block;
      margin: 0 auto 5px auto;
      width: 80px;
      background-image: url(${btnMic});
      background-repeat: no-repeat;
      background-size: 69px auto;
      background-position: top center;
      cursor: pointer;
      padding-top: 75px;
      font-size: 15px;
      text-align: center;

      &.stop {
        background-image: url(${btnStop});
      }
    }

    span.undo {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-125px);
      display: block;
      background-image: url(${btnRedo});
      background-repeat: no-repeat;
      background-size: 38px auto;
      background-position: top center;
      width: 80px;
      padding-top: 45px;
      cursor: pointer;
      text-align: center;
      font-size: 15px;
    }
  }

  // 次へボタン
  .btn-next {
    padding: 30px 0;
    width: 90px;
    display: block;
    font-size: 20px;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin: auto;
  }
`;