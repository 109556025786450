import axios, {AxiosPromise} from "axios";
import {ForgotRequest, ListByEmployeeRequest, LoginRequest, RegisterRequest, RegisterVoiceRequest, ResetRequest} from "../typings";
import {MimosysReportRequest} from "../../../react-common/typings";

// 名前の重複をさけるため、各定数の先頭に "Api" をつける

// 共通のリクエストインスタンス
const AxiosInterface = axios.create();
AxiosInterface.interceptors.request.use((config) => {
    const token: string = localStorage.getItem("alcohol_token") ? localStorage.getItem("alcohol_token") as string : "";
    config.headers = {
        "Authorization": `Bearer ${token}`,
    };
    return config;
});

// 企業情報の取得
export const ApiGetCompany = (companyId: string): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company/get/${companyId}`));
};

// ログイン
export const ApiLogin = (req: LoginRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/employee/login`), req);
};

// ログイン
export const ApiLogout = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/employee/logout`));
};

// パスワード再発行メール送信
export const ApiForgot = (req: ForgotRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/employee/forgot`), req);
};

// パスワード再設定
export const ApiReset = (req: ResetRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/employee/reset`), req);
};

// 自分の情報取得
export const ApiMe = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/employee/me`));
};

// 登録状況チェック
export const ApiCheck = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/employee-history/check`));
};

// 出退勤登録
export const ApiRegister = (req: RegisterRequest): AxiosPromise<any> => {

    const formData = new FormData();
    formData.append("file", req.file);
    formData.append("history_type", req.history_type);
    formData.append("mimosys_temp_key", req.mimosys_temp_key);

    if (req.latitude) {
        formData.append("latitude", String(req.latitude));
    }

    if (req.longitude) {
        formData.append("longitude", String(req.longitude));
    }

    if (req.car_id) {
        formData.append("car_id", req.car_id);
    }

    return AxiosInterface.post(createEndpointUrl(`/employee-history/register`), formData);
};

// 履歴取得
export const ApiHistory = (req: ListByEmployeeRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.append("page", String(req.page));
    params.append("limit", String(req.limit));
    params.append("ym", req.ym);
    return AxiosInterface.get(createEndpointUrl(`/employee-history?${params.toString()}`));
};

// 車両リスト取得
export const ApiCarList = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/employee-history/car-list`));
};

// 音声登録
export const ApiRegisterVoice = (req: RegisterVoiceRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/employee-history/register/voice`), req);
};

// Mimosysレポート取得
export const ApiMimosysReport = (req: MimosysReportRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.append("employee_id", req.employee_id ?? "");
    params.append("from", req.from);
    params.append("to", req.to);

    return AxiosInterface.get(createEndpointUrl(`/employee-history/mimosys-report?${params.toString()}`));
};

// ドメイン名を連結して返す
const createEndpointUrl = (path: string): string => {
    return `${process.env.REACT_APP_ALCOHOL_API_ENDPOINT}${path}`;
}
