import React, {useContext} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import {Header} from "../common/Header";
import {StyledLayout} from "./StyledLayout";

// 完了画面
export const RegisterComplete = () => {

    const {company} = useContext(AppContext);

    return <AuthCheck>

        <Header/>

        <Screen3Style>

            <h3>送信が完了しました</h3>

            <p>安全運転管理者にビデオ通話で報告してください</p>

            <NavLink to={`/?company=${company?.id}`}>TOPに戻る</NavLink>

        </Screen3Style>

    </AuthCheck>

};


const Screen3Style = styled(StyledLayout)`
  max-width: 327px;

  h3 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 18px;
  }

  p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 32px;
  }

  a {
    display: block;
    font-size: 18px;
    background-color: #031F69;
    text-align: center;
    color: #fff;
    padding: 10px 0;
    text-decoration: none;
    border-radius: 2px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);
  }





`