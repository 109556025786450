import styled from "styled-components";

interface Props {
    message: string | null;
}

export const Message = (props: Props) => {

    if (!props.message) {
        return null;
    }

    return <StyledMessage>
        {props.message}
    </StyledMessage>
};


const StyledMessage = styled.div`
  background-color: #ecc8c8;
  color: red;
  margin-bottom: 15px;
  border: 1px solid #cc0000;
  padding: 10px;
  border-radius: 4px;

`
