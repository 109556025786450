import {AppProvider} from "../context/AppContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Login} from "./auth/Login";
import {Forgot} from "./auth/Forgot";
import {Reset} from "./auth/Reset";
import {Home} from "./main/Home";
import {RegisterStep1} from "./main/RegisterStep1";
import {RegisterStep2} from "./main/RegisterStep2";
import {RegisterComplete} from "./main/RegisterComplete";
import {CompanyCheck} from "./common/CompanyCheck";
import {Spinner} from "./common/Spinner";
import {History} from "./main/History";
import {Logout} from "./auth/Logout";
import {RegisterMimosys} from "./main/RegisterMimosys";
import {MimosysResult} from "./main/MimosysResult";

const App = () => {

    return <AppProvider>

        <Spinner/>

        <CompanyCheck>

            <BrowserRouter>
                <Routes>
                    {/*認証不要*/}
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot" element={<Forgot/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    {/*要ログイン認証*/}
                    <Route path="/" element={<Home/>}/>
                    {/*Mimosys音声登録*/}
                    <Route path="/register/mimosys" element={<RegisterMimosys/>}/>
                    {/*車両選択*/}
                    <Route path="/register/step1" element={<RegisterStep1/>}/>
                    {/*検知画像撮影*/}
                    <Route path="/register/step2" element={<RegisterStep2/>}/>
                    {/*完了画面*/}
                    <Route path="/register/complete" element={<RegisterComplete/>}/>
                    {/*履歴画面*/}
                    <Route path="/history" element={<History/>}/>
                    {/*MIMOSYSのレポート*/}
                    <Route path="/mimosys" element={<MimosysResult/>}/>
                </Routes>
            </BrowserRouter>

        </CompanyCheck>

    </AppProvider>

};


export default App;
