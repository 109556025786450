// 数値のゼロパディング(とりあえず、必要な桁数のみ対応)
export const zerofill = (i: number, digits: number): string => {

    switch (digits) {
        case 2:
            if (i < 10) {
                return `0${i}`;
            }
            break;
        case 3:
            if (i < 10) {
                return `00${i}`;
            }
            if (i < 100) {
                return `0${i}`;
            }
            break;
    }

    return `${i}`;

}

// Date型からYYYY-MMを作成
export const createYm = (dt: Date): string => {
    return `${dt.getFullYear()}-${zerofill(dt.getMonth() + 1, 2)}`;
}

// YYYY-MM-DD HH:MM:SSの文字列をHH:MMに変換
export const createTime = (dt: string): string => {

    const parts = dt.split(" ")
    const parts2 = parts[1].split(":")

    return `${parts2[0]}:${parts2[1]}`;
}

// YYYY-MM-DDを04月01日(金)の形式に変更
export const createJaMdFromYmd = (str: string): string => {

    const parts = str.split("-");
    const dt = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];

    return `${zerofill(dt.getMonth() + 1, 2)}月${zerofill(dt.getDate(), 2)}日(${weekdays[dt.getDay()]})`;
}

// Date型を04月01日の形式に変更
export const createJaMdFromDt = (dt: Date): string => {
    return `${zerofill(dt.getMonth() + 1, 2)}月${zerofill(dt.getDate(), 2)}日`;
}

// Date型をYYYY-MM-DDに変換
export const createYmdFromDt = (dt: Date): string => {
    return `${dt.getFullYear()}-${zerofill(dt.getMonth() + 1, 2)}-${zerofill(dt.getDate(), 2)}`;
}

// スマホ判別（簡易的）
export const checkIsMobile = (): boolean => {
    const reg = /iPhone|Android/;
    return reg.test(navigator.userAgent);
};
