import * as React from "react";
import {PropsWithChildren, useEffect, useState} from "react";
import {AppContextData} from "../typings";
import {CompanyData, DivisionData, EmployeeData} from "../../../react-common/typings";

export const AppContext = React.createContext({} as AppContextData);

export const AppProvider = ({children}: PropsWithChildren<any>): JSX.Element => {

    const [company, setCompany] = useState<CompanyData | null>(null);
    const [division, setDivision] = useState<DivisionData | null>(null);
    const [employee, setEmployee] = useState<EmployeeData | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [spinner, setSpinner] = useState<boolean>(false);

    useEffect(() => {
        // 以前のトークンを設定
        setToken(localStorage.getItem("alcohol_token"));
    }, []);

    const defaultContext: AppContextData = {

        company: company,
        setCompany: setCompany,

        division: division,
        setDivision: setDivision,

        employee: employee,
        setEmployee: setEmployee,

        token: token,
        setToken: (d) => {
            setToken(d);

            if (d != null) {
                localStorage.setItem("alcohol_token", d);
            } else {
                localStorage.removeItem("alcohol_token");
            }
        },

        spinner: spinner,
        setSpinner: setSpinner,
    };

    return <AppContext.Provider value={defaultContext}>
        {children}
    </AppContext.Provider>

};
