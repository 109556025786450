import * as React from "react";
import styled from "styled-components";
import {ReportData} from "../../../../../react-common/typings";
import {createYmdFromDt} from "../../../utility/utility";

interface Props {
    fromDt: Date;
    toDt: Date;
    reportData: ReportData[];
    valueType: string;
    historyType: string;
}

export const MiniGraph = (props: Props) => {

    const days = (props.toDt.getTime() - props.fromDt.getTime()) / (86400 * 1000);

    const dateArr: Date[] = [];
    for (let i = 1; i <= days; i++) {
        const s = props.fromDt.getTime() + ((86400 * 1000) * i);
        dateArr.push(new Date(s));
    }

    const stageWidth = 175;
    const stageHeight = 90;
    const innerWidth = stageWidth - 10;
    const innerHeight = stageHeight * .85;
    const stepX = innerWidth / days;

    // 日付ラベルの作成
    const getDays = (): JSX.Element[] => {

        const elements: JSX.Element[] = [];

        dateArr.forEach((dt, i) => {
            const x = i * stepX;
            elements.push(<text key={`day-${i}`} y={0} x={x}>{dt.getDate()}</text>);
        });

        return elements;
    }

    // ラインの描画のためのパスを返す
    const getPath = (): string => {

        const paths: string[] = [];
        dateArr.forEach((dt, i) => {
            const ymd = createYmdFromDt(dt);

            for (let o = 0; o <= 1; o++) {

                const y = getYValue(ymd, o);

                let x = i * stepX;
                if (o === 1) {
                    // すでに描画されている日付の場合、stepX / 4分右にずらして描画
                    x += stepX / 2;
                }

                console.log(ymd, y, o);

                if (y !== 9999) {
                    if (paths.length === 0) {
                        paths.push(`M${x} ${y}`);
                    } else {
                        paths.push(`L${x} ${y}`);
                    }
                }
            }
        });

        return paths.join(" ");
    };

    // ラインの描画のためのパスを返す
    const getCircles = (): JSX.Element[] => {

        const circles: JSX.Element[] = [];
        dateArr.forEach((dt, i) => {
            const ymd = createYmdFromDt(dt);

            for (let o = 0; o <= 1; o++) {
                let x = i * stepX;
                if (o === 1) {
                    // すでに描画されている日付の場合、stepX / 2分右にずらして描画
                    x += stepX / 2;
                }
                const y = getYValue(ymd, o);
                if (y !== 9999) {
                    circles.push(<circle key={`circle-${i}-${o}`} fill="#ffffff" strokeWidth={1.5} stroke="#031F69" cx={x} cy={y} r={3}/>);
                }
            }
        });

        return circles;
    };

    // 日付を指定して、値を抽出
    const getYValue = (ymd: string, offset: number): number => {
        let o = 0;
        for (let a = 0; a < props.reportData[0].data.length; a++) {
            const d = props.reportData[0].data[a];
            if (ymd === d.ymd) {

                if (o !== offset) {
                    o++;
                    continue;
                }

                let value = 0;
                if (props.valueType === "vitality") {
                    value = d.vitality;
                } else if (props.valueType === "mental_activity") {
                    value = d.mental_activity;
                }

                if (value !== 0) {
                    // 値がある場合のみ返す
                    return innerHeight * (1 - value);
                }
                break;
            }
        }

        // ない場合、9999を返す
        return 9999;
    };


    // 平均帯を返す
    const getAverageBand = (): JSX.Element | null => {

        let low, high;

        if (props.valueType === "vitality") {
            low = props.reportData[0].vitality_low / 100;
            high = props.reportData[0].vitality_high / 100;
        } else {
            low = props.reportData[0].mental_activity_low / 100;
            high = props.reportData[0].mental_activity_high / 100;
        }

        if (low === 0 || high === 0) {
            // 算出されていない場合描画しない
            return null;
        }

        const lowEnd = innerHeight * (1 - low);
        const highEnd = innerHeight * (1 - high);

        return <React.Fragment>
            <text className="average" fill="#031F69" x={0} y={highEnd - 3}>あなたの平均</text>
            <rect fill="#031F69" opacity={0.2} x={0} y={highEnd} width={innerWidth} height={lowEnd - highEnd}/>
        </React.Fragment>
    }

    return <StyledMiniGraph>

        <svg width={stageWidth} height={stageHeight} viewBox={`0 0 ${stageWidth} ${stageHeight}`}>

            <g transform={`translate(7 0.5)`}>
                <line stroke="#ccc" strokeWidth={1} x1={0} y1={0} x2={innerWidth} y2={0}/>
                <line stroke="#ccc" strokeWidth={1} x1={0} y1={innerHeight / 2} x2={innerWidth} y2={innerHeight / 2}/>
                <line stroke="#ccc" strokeWidth={1} x1={0} y1={innerHeight} x2={innerWidth} y2={innerHeight}/>
                <path stroke="#031F69" fill="none" strokeWidth={2} d={getPath()}/>
                {getAverageBand()}
                {getCircles()}
            </g>

            <g className="day-label" transform={`translate(10 ${innerHeight + 3})`}>
                {getDays()}
            </g>

        </svg>


    </StyledMiniGraph>;

};

const StyledMiniGraph = styled.div`

  svg {
    width: 100%;
    height: auto;

    .day-label {
      text {
        font-size: 10px;
        text-anchor: middle;
        dominant-baseline: hanging;
        fill: #777;
      }
    }

    .average {
      font-size: 8px;
    }

  }

`;