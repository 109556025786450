import styled from "styled-components";
import * as React from "react";
import {useContext} from "react";
import {NavLink} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import logo from "../../images/logo.svg";

export const Header = () => {

    const {company} = useContext(AppContext);

    return <HeaderStyle>
        <h1><NavLink to={`/?company=${company?.id}`}>TRIBAWL</NavLink></h1>
        <NavLink className="logout" to={`/logout?company=${company?.id}`}>ログアウト</NavLink>
    </HeaderStyle>
};

const logoWidth = 55;

export const HeaderHeight = 80;

const HeaderStyle = styled.header`
  background-color: #fff;
  height: ${HeaderHeight}px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .16);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  justify-content: space-between;

  h1 {
    width: ${logoWidth}px;
    height: ${logoWidth}px;

    a {
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url(${logo});
      white-space: nowrap;
      text-indent: 100%;
      overflow: hidden;
      display: block;
    }
  }
  
  .logout {
    font-size: 12px;
    text-decoration: none;
    color: #000;
  }

`;

