import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {AuthCheck} from "../common/AuthCheck";
import styled from "styled-components";
import {createJaMdFromDt, createJaMdFromYmd, zerofill} from "../../utility/utility";
import {Header, HeaderHeight} from "../common/Header";
import {AppContext} from "../../context/AppContext";
import {ApiMimosysReport} from "../../api/Api";
import {MimosysReportRequest, MimosysReportResponse, ReportData} from "../../../../react-common/typings";
import {Message} from "../common/Message";
import {NavLink, useLocation} from "react-router-dom";
import {Meter} from "./mimosys/Meter";
import {useSwipeable} from "react-swipeable";
import icoUp from "../../images/ico-up.svg";
import icoNormal from "../../images/ico-normal.svg";
import icoDown from "../../images/ico-down.svg";
import {MiniGraph} from "./mimosys/MiniGraph";

export const MimosysResult = () => {

    const location = useLocation();
    const {employee, setSpinner} = useContext(AppContext);
    const [message, setMessage] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [reportData, setReportData] = useState<ReportData[] | null>(null);
    const [historyType, setHistoryType] = useState<string>("");
    const [reportOffset, setReportOffset] = useState<number>(0);
    // const [swipeAmount, setSwipeAmount] = useState<number>(0);
    const fromDt = new Date(new Date().getTime() - 86400 * 1000 * 7);
    const toDt = new Date();

    useEffect(() => {
        if (employee) {
            // レポート取得
            getReport();
        }

        const params = new URLSearchParams(location.search);

        setHistoryType(params.get("type") ?? "attendance");


    }, [employee]);

    // swipe設定
    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            if (reportOffset === 1) {
                setReportOffset(0);
            }
        },
        onSwipedLeft: () => {
            if (reportOffset === 0) {
                setReportOffset(1);
            }
        },
        // onSwiping: (e) => {
        //     // setSwipeAmount(e.deltaX)
        // },
    });

    // レポート取得
    const getReport = () => {

        // 過去1週間分のレポートを取得
        const req: MimosysReportRequest = {
            employee_id: employee?.id,
            from: `${fromDt.getFullYear()}-${zerofill(fromDt.getMonth() + 1, 2)}-${zerofill(fromDt.getDate(), 2)}`,
            to: `${toDt.getFullYear()}-${zerofill(toDt.getMonth() + 1, 2)}-${zerofill(toDt.getDate(), 2)}`,
        };

        setSpinner(true);

        ApiMimosysReport(req)
            .then((res) => {
                const data = res.data as MimosysReportResponse;
                setReportData(data.reports);
            })
            .catch((err) => {
                console.log(err);
                if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
                setIsLoaded(true);
            });
    };

    // 値を取得
    const getValue = (valueType: string) => {

        let value = 0;

        if (!reportData) {
            return value;
        }

        const lastData = reportData[0].data[reportData[0].data.length - 1];

        // 最後の値（最新）を返すこと。
        if (valueType === "vitality") {
            // 元気圧 + 出勤
            value = lastData.vitality;
        }else if (valueType === "mental_activity") {
            // 心の活量値 + 出勤
            value = lastData.mental_activity;
        }
        return Math.ceil(value * 100);
    };

    // ラベルを返す
    const getLabel = (valueType: string) => {
        if (valueType === "mental_activity") {
            return "心の活量値";
        }

        return "元気圧";
    };

    // ドットクリック
    const onClickDot = (offset: number): void => {
        setReportOffset(offset);
    }

    // 値の遷移を取得
    const getTransition = (valueType: string) => {

        if (!reportData) {
            return "normal";
        }

        const valueLength = reportData[0].data.length;

        let lastValue: number = 0;

        const lastData = reportData[0].data[valueLength - 1];

        if (valueType === "vitality") {
            // 元気圧
            lastValue = lastData.vitality * 100;
        } else if (valueType === "mental_activity") {
            // 心の活量値
            lastValue = lastData.mental_activity * 100;
        }

        let low, high;
        if (valueType === "vitality") {
            low = reportData[0].vitality_low;
            high = reportData[0].vitality_high;
        } else {
            low = reportData[0].mental_activity_low;
            high = reportData[0].mental_activity_high;
        }

        // 事前計算した上限を超えていたら上昇、下限を下回っていたら下降
        if (high !== 0 && lastValue > high) {
            // 上昇傾向
            return "up";
        } else if (low !== 0 && lastValue < low) {
            // 下降傾向
            return "down";
        }

        return "normal";
    };

    const dt = new Date();

    const leftPos = reportOffset === 0 ? "0" : "-100vw";

    const vitalityTransition = getTransition("vitality");
    const mentalActivityTransition = getTransition("mental_activity");

    if (!isLoaded) {
        // 読み込み完了していない場合は待つ
        return <AuthCheck>
            <Header/>
            <StyledMimosysResult>
                <Message message={message}/>
            </StyledMimosysResult>
        </AuthCheck>
    }

    return <AuthCheck>

        <Header/>

        <StyledMimosysResult>

            <Message message={message}/>

            <h4>{createJaMdFromYmd(`${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`)}</h4>

            <div className="meters-wrapper">
                <div className="meters" style={{left: leftPos}} {...swipeHandlers}>
                    <Meter label={getLabel("vitality")} value={getValue("vitality")} offset={1}/>
                    <Meter label={getLabel("mental_activity")} value={getValue("mental_activity")} offset={2}/>
                </div>
            </div>

            <ul className="dots">
                <li><span className={reportOffset === 0 ? "active" : ""} onClick={() => onClickDot(0)}/></li>
                <li><span className={reportOffset === 1 ? "active" : ""} onClick={() => onClickDot(1)}/></li>
            </ul>

            <div className="history">
                <div className="left">
                    <h6>元気圧</h6>
                    <div className={`transition ${vitalityTransition}`}>
                        {vitalityTransition === "up" && <React.Fragment><img src={icoUp} alt=""/>いい調子</React.Fragment>}
                        {vitalityTransition === "normal" && <React.Fragment><img src={icoNormal} alt=""/>普段通り</React.Fragment>}
                        {vitalityTransition === "down" && <React.Fragment><img src={icoDown} alt=""/>下降気味</React.Fragment>}
                    </div>
                </div>
                <div className="right">
                    <span className="term">{createJaMdFromDt(fromDt)}〜{createJaMdFromDt(toDt)}</span>
                    <MiniGraph historyType={historyType} valueType="vitality" fromDt={fromDt} toDt={toDt} reportData={reportData ?? []}/>
                </div>
            </div>

            <div className="history">
                <div className="left">
                    <h6>心の活量値</h6>
                    <div className={`transition ${mentalActivityTransition}`}>
                        {mentalActivityTransition === "up" && <React.Fragment><img src={icoUp} alt=""/>いい調子</React.Fragment>}
                        {mentalActivityTransition === "normal" && <React.Fragment><img src={icoNormal} alt=""/>普段通り</React.Fragment>}
                        {mentalActivityTransition === "down" && <React.Fragment><img src={icoDown} alt=""/>下降気味</React.Fragment>}
                    </div>
                </div>
                <div className="right">
                    <span className="term">{createJaMdFromDt(fromDt)}〜{createJaMdFromDt(toDt)}</span>
                    <MiniGraph historyType={historyType} valueType="mental_activity" fromDt={fromDt} toDt={toDt} reportData={reportData ?? []}/>
                </div>
            </div>

            <div className="back">
                <NavLink to="/register/complete">戻る</NavLink>
            </div>

        </StyledMimosysResult>

    </AuthCheck>

};


const StyledMimosysResult = styled.div`
  padding: ${HeaderHeight + 50}px 0 0 0;

  h4 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
  }

  h5 {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
  }

  span.value {
    font-size: 80px;
    text-align: center;
    font-weight: 600;
    display: block;
  }

  // メーター
  .meters-wrapper {
    overflow: hidden;
    width: 100vw;
    margin-bottom: 10px;
    position: relative;
    height: 179px;

    .meters {
      display: flex;
      width: 200vw;
      position: absolute;
      height: 179px;
      top: 0;
      left: 0;
      transition: all 0.3s;

      .meter {
        width: 100vw !important;
        text-align: center;
      }
    }
  }

  // メーターの下のドット
  .dots {
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    li {
      margin-right: 10px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      span {
        display: block;
        width: 8px;
        height: 8px;
        background-color: #A2A2A2;
        border-radius: 4px;

        &.active {
          background-color: #031F69;
        }
      }
    }
  }

  // 一週間の履歴
  .history {
    width: 310px;
    margin: 0 auto 30px auto;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
    padding: 20px 10px;
    display: flex;

    .left {
      width: 80px;

      h6 {
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 25px;
      }

      // 傾向
      .transition {
        font-size: 15px;

        img {
          display: block;
          margin: 0 0 5px 10px;
          width: 40px;
          height: auto;
        }

        &.up {
          color: #F77777;
        }

        &.normal {
          color: #E8CA77;
        }

        &.down {
          color: #5270B4;
        }
      }
    }

    .right {
      flex: 1;

      .term {
        font-size: 15px;
        display: block;
        text-align: right;
        padding-bottom: 10px;
      }
    }
  }

  .back {
    text-align: center;
    padding: 0 0 30px 0;

    a {
      color: #000;
      font-size: 20px;
    }
  }

`;

