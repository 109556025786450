import * as React from "react";
import {useEffect, useState} from "react";
import {checkIsMobile} from "../../utility/utility";
import {Message} from "./Message";

export const MobileCheck = ({children}: React.PropsWithChildren) => {

    const [isMobile, setIsMobile] = useState<boolean>(true);

    useEffect(() => {

        // モバイル判別
        setIsMobile(checkIsMobile());

    }, []);

    if (!isMobile) {
        return <React.Fragment>
            <div style={{width: "90%", padding: "100px 0", margin: "auto"}}>
                <Message message="出退勤登録はスマートフォンからおこなってください。"/>
            </div>
        </React.Fragment>
    }

    return <React.Fragment>

        {children}

    </React.Fragment>

}
