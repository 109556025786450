import styled from "styled-components";

interface Props {
    label: string;
    value: number;
    offset: number;
}

export const Meter = (props: Props) => {

    const stageWidth = 310;
    const stageHeight = 179;

    const rectWidth = 295;

    const rectX = rectWidth * (props.value / 100);

    const maskId = `meter-${props.offset}`;

    return <StyledMeter className="meter">

        <svg width={stageWidth} height={stageHeight} viewBox={`0 0 ${stageWidth} ${stageHeight}`}>

            <g transform={`translate(7 ${stageHeight / 2 - 75})`}>

                <path className="half-circle" d="M287.5,155c-4.14,0-7.5-3.36-7.5-7.5,0-73.06-59.44-132.5-132.5-132.5S15,74.44,15,147.5c0,4.14-3.36,7.5-7.5,7.5s-7.5-3.36-7.5-7.5C0,66.17,66.17,0,147.5,0s147.5,66.17,147.5,147.5c0,4.14-3.36,7.5-7.5,7.5Z"/>
                <path clipPath={`url(#${maskId})`} className="half-circle-wrapper" d="M287.5,155c-4.14,0-7.5-3.36-7.5-7.5,0-73.06-59.44-132.5-132.5-132.5S15,74.44,15,147.5c0,4.14-3.36,7.5-7.5,7.5s-7.5-3.36-7.5-7.5C0,66.17,66.17,0,147.5,0s147.5,66.17,147.5,147.5c0,4.14-3.36,7.5-7.5,7.5Z"/>
                <clipPath id={maskId}>
                    <rect width={rectWidth} height={155} fill="#ff0000" x={-rectWidth} y={0} transform={`translate(${rectX} 0)`} opacity={1}/>
                </clipPath>
            </g>

            <text className="label" x={stageWidth / 2} y={stageHeight / 2 - 20}>{props.label}</text>
            <text className="value" x={stageWidth / 2} y={stageHeight / 2 + 50}>{props.value}</text>

        </svg>

    </StyledMeter>

};

const StyledMeter = styled.div`
  svg {
    .half-circle {
      fill: #C8D7E3;
    }

    .half-circle-wrapper {
      fill: #031F69;
    }

    text {
      text-anchor: middle;
      dominant-baseline: central;

      &.label {
        font-size: 20px;
      }

      &.value {
        font-size: 80px;
        font-weight: bold;
      }
    }

  }

`;
