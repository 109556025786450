import styled from "styled-components";

interface Props {
    message: string | undefined | null;
}

// 各フォーム項目に表示するエラーコンポーネント
export const FormError = (props: Props) => {

    if (!props.message) {
        return null;
    }

    return <StyledFormError>
        {props.message}
    </StyledFormError>
};

const StyledFormError = styled.div`
  color: #ff0000;
  margin-top: 10px;
`;