import React, {useContext, useEffect, useRef, useState} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import {ApiCheck, ApiRegister} from "../../api/Api";
import {CheckResponse, RegisterRequest} from "../../typings";
import {Preview} from "./Preview";
import {Message} from "../common/Message";
import {FormError} from "../common/FormError";
import {CommonApiResponse} from "../../../../react-common/typings";
import {Header} from "../common/Header";
import {StyledLayout} from "./StyledLayout";
import {MobileCheck} from "../common/MobileCheck";

// 写真送信
export const RegisterStep2 = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {company, setSpinner, employee} = useContext(AppContext);
    const [isTypeError, setIsTypeError] = useState<boolean>(false);
    const [historyType, setHistoryType] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [message, setMessage] = useState<string | null>(null);
    const [carId, setCarId] = useState<string>("");

    useEffect(() => {

        // 種別をチェック
        getHistoryType();

        const params = new URLSearchParams(location.search);
        const carId = params.get("car_id") ?? "";

        setCarId(carId);

    }, [employee]);

    // 種別を取得
    const getHistoryType = () => {

        setMessage(null);

        // クエリストリングのtypeを取得
        const params = new URLSearchParams(window.location.search);
        const historyType = params.get("type") ?? "";

        if (["attendance", "leaving"].indexOf(historyType) === -1) {
            setIsTypeError(true);
            setMessage("種別指定が不正です。");
            return;
        }

        // 種別設定
        setHistoryType(historyType);

        // 送信状況をチェック
        checkSendingStatus();
    };

    // 送信状況のチェック
    const checkSendingStatus = () => {

        setSpinner(true);

        ApiCheck()
            .then((res) => {
                const data = res.data as CheckResponse;

                if (historyType === "attendance" && data.attendance) {
                    setMessage("すでに出勤時の登録は完了しています。");
                    setIsTypeError(true);
                }

                if (historyType === "leaving" && data.leaving) {
                    setMessage("すでに退勤時の登録は完了しています。");
                    setIsTypeError(true);
                }

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // 変更イベントハンドラー
    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        e.preventDefault();

        if (!e.currentTarget.files) {
            return;
        }

        const file = e.currentTarget.files.item(0);
        setFile(file);
    };

    // 撮影ボタンクリック
    const onCaptureClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    // キャンセル時処理
    const onCancel = () => {
        setFile(null);
    };

    // 送信前に座標を取得
    const beforeSend = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault()

        // 位置情報取得
        navigator.geolocation.getCurrentPosition((position) => {
            onSubmit(position.coords.latitude, position.coords.longitude);
        }, () => {
            // 座標取得エラー時でも送信は可能とする
            onSubmit(null, null);
        });
    };

    // 送信
    const onSubmit = (latitude: number | null, longitude: number | null): void => {

        console.log(latitude, longitude);

        if (!file) {
            setMessage("写真が選択されていません。");
            return;
        }
        setMessage(null);
        setSpinner(true);

        const req: RegisterRequest = {
            file: file,
            history_type: historyType,
            latitude: latitude,
            longitude: longitude,
            mimosys_temp_key: localStorage.getItem("mimosys_temp_key") ?? "",
        };

        if (historyType === "attendance") {
            req.car_id = carId;
        }

        ApiRegister(req)
            .then((res) => {
                const data = res.data as CommonApiResponse;
                console.log(data);

                // 完了時、localStorageを削除
                localStorage.removeItem("mimosys_temp_key");


                // 画面遷移
                if (company?.facility?.with_mimosys) {
                    // mimosys結果画面に遷移
                    navigate(`/mimosys?company=${company?.id}&type=${historyType}`);
                } else {
                    // 通常の完了画面に遷移
                    navigate(`/register/complete?company=${company?.id}`);
                }

            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    console.log(err.response.data);
                    const keys = Object.keys(err.response.data);
                    const _tmp: { [key: string]: string } = {};
                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });

                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });

    };

    if (isTypeError) {
        // 種別が不正な場合
        return <Navigate to={`/?company=${company?.id}`}/>
    }

    const classes: string[] = [];

    if (file) {
        classes.push("selected");
    }

    return <AuthCheck>

        <Header/>

        <MobileCheck>

            <Screen2Style className={classes.join(" ")}>

                <form onSubmit={beforeSend}>

                    <Message message={message}/>

                    <p>アルコール検知器の結果の<br/>写真を送って下さい</p>

                    <Preview file={file}/>

                    <input type="file" name="file" capture="environment" accept="image/jpeg" onChange={onChange} ref={inputRef}/>

                    <FormError message={errors["file"]}/>
                    <FormError message={errors["history_type"]}/>

                    {!file && <div className="buttons">
                        <button type="button" onClick={onCaptureClick}>写真を撮る</button>
                    </div>}

                    {file && <div className="buttons">
                        <button type="button" className="btn-cancel" onClick={onCancel}>キャンセル</button>
                        <button className="btn-send">送信</button>
                    </div>}

                </form>

            </Screen2Style>

        </MobileCheck>

    </AuthCheck>

};

const Screen2Style = styled(StyledLayout)`
  max-width: 250px;

  p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 80px;
    line-height: 1.6;
  }

  input[type=file] {
    display: none;
  }

  .buttons {
    width: 205px;
    margin: auto;

    button {
      display: block;
      width: 100%;
      height: 54px;
      margin-bottom: 30px;
      text-align: center;
      text-decoration: none;
      border: none;
      background-color: #fff;
      font-size: 20px;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);
    }
  }

  .history {
    text-align: center;
  }

  // ファイル選択中
  &.selected {
    max-width: 320px;

    p {
      margin-bottom: 40px;
    }

    .preview {
      img {
        display: block;
        max-width: 90%;
        height: auto;
        margin: 0 auto 30px auto;
      }
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      button {
        font-size: 20px;
        background-color: #031F69;
        color: #fff;
        width: 48%;
        border-radius: 2px;
        box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);

        &.btn-cancel {
          background-color: #fff;
          color: #000;
        }

      }
    }

  }


`
