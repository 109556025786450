import React, {useContext, useEffect, useState} from "react";
import {AuthCheck} from "../common/AuthCheck";
import {Navigate, useNavigate} from "react-router-dom";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import {ApiCarList, ApiCheck} from "../../api/Api";
import {CarListResponse, CheckResponse} from "../../typings";
import {Message} from "../common/Message";
import {CarData} from "../../../../react-common/typings";
import {Header} from "../common/Header";
import {StyledLayout} from "./StyledLayout";
import icoDown from "../../images/down.svg";
import {MobileCheck} from "../common/MobileCheck";

// 写真送信
export const RegisterStep1 = () => {

    const {company, setSpinner, employee} = useContext(AppContext);
    const [isTypeError, setIsTypeError] = useState<boolean>(false);
    const [historyType, setHistoryType] = useState<string>("");
    const [carId, setCarId] = useState<string>("");
    const [cars, setCars] = useState<CarData[]>([]);
    const [message, setMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {

        if (employee) {
            // 車両情報を取得
            getCars();
        }

        // 種別をチェック
        getHistoryType();

    }, [employee]);

    // 種別を取得
    const getHistoryType = () => {

        setMessage(null);

        // クエリストリングのtypeを取得
        const params = new URLSearchParams(window.location.search);
        const historyType = params.get("type") ?? "";

        if (historyType !== "attendance") {
            // 車両選択は出勤時のみの項目なので、historyTypeはattendanceのみとなる。
            setIsTypeError(true);
            setMessage("種別指定が不正です。");
            return;
        }

        // 種別設定
        setHistoryType(historyType);

        // 送信状況をチェック
        checkSendingStatus();
    };

    // 車両情報取得
    const getCars = () => {
        setSpinner(true);
        ApiCarList()
            .then((res) => {
                const data = res.data as CarListResponse;
                setCars(data.cars);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // 送信状況のチェック
    const checkSendingStatus = () => {

        setSpinner(true);

        ApiCheck()
            .then((res) => {
                const data = res.data as CheckResponse;

                if (historyType === "attendance" && data.attendance) {
                    // すでに登録済の場合
                    setIsTypeError(true);
                }

            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    if (isTypeError) {
        // 種別が不正な場合
        return <Navigate to={`/?company=${company?.id}`}/>
    }

    // 車両選択ハンドラー
    const onCarChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        e.preventDefault();
        setCarId(e.currentTarget.value);
    };

    // 車両選択完了
    const onCarDetermined = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (carId === "") {
            setMessage("車両が選択されていません。");
            return;
        }

        setMessage(null);
        navigate(`/register/step2?company=${company?.id}&type=${historyType}&car_id=${carId}`);
    };

    // 出勤時、車両選択を挟む
    return <AuthCheck>

        <Header/>

        <MobileCheck>

            <Screen2Style>

                <form onSubmit={onCarDetermined}>

                    <Message message={message}/>

                    <p>車両を選択してください。</p>

                    <select name="car_id" onChange={onCarChange} value={carId}>
                        <option value="">選択してください</option>
                        {cars.map((car) => {
                            return <option key={`car-${car.id}`} value={car.id}>{car.name}（{car.number}）</option>
                        })}
                    </select>

                    <div className="buttons">
                        <button className="btn-send">次へ進む</button>
                    </div>

                </form>

            </Screen2Style>

        </MobileCheck>

    </AuthCheck>


};

const Screen2Style = styled(StyledLayout)`
  max-width: 250px;

  p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 80px;
    line-height: 1.6;
  }

  select {
    margin: -30px 0 60px 0;
    display: block;
    width: 100%;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px 30px 5px 10px;
    font-size: 16px;
    background-image: url(${icoDown});
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
  }

  .buttons {
    width: 205px;
    margin: auto;

    button {
      display: block;
      width: 100%;
      height: 54px;
      margin-bottom: 30px;
      text-align: center;
      text-decoration: none;
      border: none;
      background-color: #fff;
      font-size: 20px;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);
    }
  }

`
