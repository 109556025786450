import styled from "styled-components";
import logo from "../../images/logo.svg";

const logoWh = 103;

export const StyledLoginForm = styled.div`
  max-width: 390px;
  width: 90%;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    width: ${logoWh}px;
    height: ${logoWh}px;
    background-image: url(${logo});
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    margin: 0 auto 50px auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  h2 {
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 18px;
  }

  form {
    width: 327px;
    margin: auto;
  }

  div {
    margin-bottom: 30px;
  }

  p {
    font-size: 15px;
    line-height: 1.6em;
    margin-bottom: 32px;

    &.center {
      text-align: center;
    }
  }

  input {
    display: block;
    width: 100%;
    font-size: 18px;
    border-radius: 2px;
    border: 1px solid #061F69;
    height: 44px;
    padding: 0 10px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);
  }

  // パスワード忘れのリンク
  .forgot {
    text-align: right;

    a {
      color: #0072B9;
      font-size: 15px;
    }
  }

  // パスワード忘れのキャンセルリンク
  .cancel {
    margin-top: 15px;
    text-align: center;

    a {
      color: #0072B9;
      font-size: 15px;
    }
  }

  // メール送信完了
  .sent {
    background-color: #CECECE;
    text-align: center;
    margin-top: 15px;
    padding: 10px;
    border-radius: 2px;
    font-size: 18px;
  }

  // ログインに戻る
  .to-login {
    text-align: center;

    a {
      color: #0072B9;
      font-size: 20px;
      font-weight: 500;
    }
  }

  button {
    border-radius: 2px;
    background-color: #051A5A;
    font-size: 18px;
    color: #fff;
    border: none;
    height: 44px;
    width: 100%;
    margin-bottom: 24px;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, .16);
  }
`;